import React, {useState} from 'react';
import {Carousel} from 'react-bootstrap';

export default function Slider(props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="container">

      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/sala_6.jpg'}
          alt= "Sala"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Salone comune</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src= {props.imagesDir + '/extra_2.jpg'}
          alt= "Sala"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Salone comune</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/extra_4.jpg'}
          alt= "Sala"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Salone comune</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/extra_6.jpg'}
          alt= "Sala"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Salone comune</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/balcone_1.jpg'}
          alt= "Sala"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Terrazzo 1° piano</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/sala_4.jpg'}
          alt= "Sala"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Salone comune</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/extra_3.jpg'}
          alt= "Sala"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Salone comune</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/sala_2.jpg'}
          alt= "Sala"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Salone comune</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/extra_7.jpg'}
          alt= "Sala"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Salone comune</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/201_1.jpg'}
          alt= "Stanza 201"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Stanza 201</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/201_3.jpg'}
          alt= "Stanza 201"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Stanza 201</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/201_6.jpg'}
          alt= "Stanza 201"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Stanza 201</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/202_3.jpg'}
          alt= "Stanza 202"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Stanza 202</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/202_1.jpg'}
          alt= "Stanza 202"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Stanza 202</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/202_5.jpg'}
          alt= "Stanza 202"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Stanza 202</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/202_4.jpg'}
          alt= "Stanza 202"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Stanza 202</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/203_2.jpg'}
          alt= "Stanza 203"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Stanza 203</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/203_1.jpg'}
          alt= "Stanza 203"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Stanza 203</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/203_3.jpg'}
          alt= "Stanza 203"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Stanza 203</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/203_5.jpg'}
          alt= "Stanza 203"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Stanza 203</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/sala_5.jpg'}
          alt= "Salone comune"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Salone comune</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/extra_4.jpg'}
          alt= "Salone comune"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Salone comune</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          loading='lazy'
          className="d-block w-100"
          src= {props.imagesDir + '/esterno_1.jpg'}
          alt= "Ingresso"
        />
        <Carousel.Caption>
          {/* <h3>Sala</h3> */}
          <p>Ingresso</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
);
}