import React from 'react';
import EmbedMap from 'components/EmbedMap/EmbedMap';
import ScrollAnimation from 'react-animate-on-scroll';
import {Navbar, Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Fab from '@material-ui/core/Fab'
import styles from "./style.module.css"
import "./style.css"


export default function Contact() {

	return (
		<React.Fragment>

			{/* Navbar */}

			<Navbar className="header" bg="dark" variant="dark" expand="lg">
				<Navbar.Brand className="logo px-2" href="/">La Casa Di Lally</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav"  />

				<Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
					<Nav className="main_nav">
						<Link className="nav-link d-flex flex-row align-items-center justify-content-start mx-3 my-2" to="/"><span>Home</span></Link>
						<Link className="nav-link d-flex flex-row align-items-center justify-content-start mx-3 my-2" to="/booking"><span>Stanze</span></Link>
						<Link className="nav-link d-flex flex-row align-items-center justify-content-start active mx-3 my-2" to="/contact"><span>Contatti</span></Link>
						<Link className="nav-link book_button mr-auto d-flex flex-row align-items-center justify-content-center mx-3 my-2" to="/booking"><span>Prenota ora</span></Link>
						<Nav.Link className="book_button mr-auto d-flex flex-row align-items-center justify-content-center mx-3 my-2" style={{width:'170px'}} href='tel:3332488401'><i className="fas fa-phone text-white"></i><span>+39 3332488401</span></Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>


	{/* Contact */}

	<div className="contact" style={{overflow:"hidden"}}>
		<div className="row">
			
			{/* Contact Content */}
			<div className="col-lg-4 col-xl-2 my-auto offset-xl-2 offset-1">
				<ScrollAnimation animateIn="fadeIn">
					<div className="contact_content">
						<div className="contact_title"><h2>Contatti</h2></div>
						<div className="contact_list">
							<ul>
								<li>Via Aldo Moro 103c, Fiano Romano (RM), 00065</li>
								<li>+39 333 2488 401</li>
								<li>lacasadilally@gmail.com</li>
							</ul>
						</div>
					</div>
				</ScrollAnimation>
			</div>

			{/* Google Map */}
			<div className="col-lg-7 col-xl-7 offset-xl-1">
				{/* <div className={styles.contact_map}>

					<div className={styles.map}>
						<div id="google_map" className={styles.google_map}>
							<div className={styles.map_container}>
								<div> */}
									<EmbedMap width="100%" height='calc(100vh - 90px)' />
								{/* </div>
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	</div>

	{/* Footer */}

	<footer className="footer">
		<div className="footer_content">
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="footer_logo_container text-center">
							<div className="footer_logo px-2">
								<div className="px-1">La Casa Di Lally</div>
								<div></div>
							</div>
						</div>
					</div>
				</div>
				<div className="row footer_row justify-content-center text-center">
					
					{/* Address */}
					<div className="col-lg-3">
						<div className="footer_title">Indirizzo</div>
						<div className="footer_list">
							<ul>
								<li>Via aldo moro 103c</li>
								<li>Fiano romano (RM), 00065</li>
							</ul>
						</div>
					</div>

					{/* Reservations */}
					<div className="col-lg-3">
						<div className="footer_title">Contatti</div>
						<div className="footer_list">
							<ul>
								<li>+39 333 2488 401</li>
								<li>lacasadilally@gmail.com</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="row footer_row justify-content-center text-center">
					<p className="text-center footer_title">Seguici su:<Fab className='mx-3 bg-primary text-white' href="https://www.facebook.com/pages/category/Hotel---Lodging/La-casa-di-Lally-106434191441386/" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-f" style={{height:"14px", width:"14px"}}></i></Fab></p>
				</div>
			</div>
			
		</div>
		<div className="copyright pb-3 pt-5">
			{/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
			Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart-o" aria-hidden="true"></i> by <a href="https://colorlib.com" rel="noopener noreferrer" target="_blank">Colorlib</a>
			{/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
		</div>
		<div className="footer-copyright">
			<div className="container-fluid mt-2 text-center">
				<p>Website realized by <a href="https://socrateit.com/" rel="noopener noreferrer" target="_blank">SOCRATE</a></p>
			</div>
		</div>
	</footer>
		</React.Fragment>
	)
}