import React from 'react';
import Slider from 'components/Slider/Slider';
import ScrollAnimation from 'react-animate-on-scroll';
import EmbedMap from 'components/EmbedMap/EmbedMap';
import { HashLink } from 'react-router-hash-link';
import {Navbar, Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Fab from '@material-ui/core/Fab';


export default function Home() {

	return (
		<React.Fragment>

			{/* MENU */}

			<Navbar className="header" bg="dark" variant="dark" expand="lg">
				<Navbar.Brand className="logo px-2">La Casa Di Lally</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav"  />

				<Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
					<Nav className="main_nav">
						<Link className="nav-link d-flex flex-row align-items-center justify-content-start active mx-3 my-2" to="/"><span>Home</span></Link>
						<Link className="nav-link d-flex flex-row align-items-center justify-content-start mx-3 my-2" to="/booking"><span>Stanze</span></Link>
						<Link className="nav-link d-flex flex-row align-items-center justify-content-star mx-3 my-2" to="/contact"><span>Contatti</span></Link>
						<Link className="nav-link book_button mr-auto d-flex flex-row align-items-center justify-content-center mx-3 my-2" to="/booking"><span>Prenota ora</span></Link>
						<Nav.Link className="book_button mr-auto d-flex flex-row align-items-center justify-content-center mx-3 my-2" style={{width:'170px'}} href='tel:3332488401'><i className="fas fa-phone text-white"></i><span>+39 3332488401</span></Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>

			{/* HOME */}

			<div className="home" style={{backgroundImage: 'url(/images/foto/'+(window.innerHeight >= 992 ? "lg" : "sm" )+'/sala_6_blur.jpg)'}}>
				<div className="row mx-auto justify-content-center home_container">
					<div className="col-10 col-xl_12">
						<div className="home_content text-center">
							
							<ScrollAnimation animateIn="fadeIn" delay={200}>
								<h1 className="home_title">Benvenuti</h1>
								<h3 className="text-white">Nella Casa Di Lally</h3>
							</ScrollAnimation>
						</div>
					</div>
				</div>
			</div>


			{/* Booking  */}
			<div className="booking my-5">
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="booking_title text-center mb-5"><h2>Le nostre stanze</h2></div>

							{/*  Booking Slider  */}
							
							<div className="row justify-content-center">
								
								{/* Slide */}
								
									<div className="col-12 col-md">
										<ScrollAnimation animateIn="fadeIn" delay={window.innerHeight >= 992 ? 200:100} duration={1.7}>
											<div className="booking_item d-block">
												<HashLink smooth to="/booking#201" scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}>
													<div className="background_image" style={{backgroundImage:'url(images/foto/'+(window.innerHeight >= 992 ? "lg" : "sm" )+"/201_1.jpg)"}}></div>
													<div className="booking_overlay trans_200"></div>
													<div className="booking_item_content">
														<div className="booking_item_list">
															<ul>
																<li>18 m²</li>
																<li>Bagno privato</li>
																<li>Aria condizionata</li>
																<li>TV a schermo piatto</li>
																<li>WiFi gratis</li>
															</ul>
														</div>
													</div>
												</HashLink>
												{/* <div className="booking_price">€50/Notte</div>  */}
												<div className="booking_link"><p>Stanza 201</p></div>
											</div>
										</ScrollAnimation>
									</div>
								
								{/*  Slide  */}
									<div className="col-12 col-md my-2 my-md-0">
										<ScrollAnimation animateIn="fadeIn" delay={window.innerHeight >= 992 ? 450 : 100} duration={1.7}>
											<div className="booking_item d-block">
												<HashLink smooth to="/booking#202" scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}>
													<div className="background_image" style={{backgroundImage:'url(images/foto/'+(window.innerHeight >= 992 ? "lg" : "sm" )+"/202_2.jpg)"}}></div>
													<div className="booking_overlay trans_200"></div>
													<div className="booking_item_content">
														<div className="booking_item_list">
															<ul>
																<li>20 m²</li>
																<li>Bagno privato</li>
																<li>Aria condizionata</li>
																<li>TV a schermo piatto</li>
																<li>WiFi gratis</li>
															</ul>
														</div>
													</div>
													{/*  <div className="booking_price">€50/Notte</div>  */}
													<div className="booking_link"><p>Stanza 202</p></div>
												</HashLink>
											</div>
										</ScrollAnimation>
									</div>	
								
								{/*  Slide  */}
									<div className="col-12 col-md">
										<ScrollAnimation animateIn="fadeIn" delay={window.innerHeight >= 992 ? 700:100} duration={1.7}>
											<div className="booking_item d-block">
												<HashLink smooth to="/booking#203" scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}>
													<div className="background_image" style={{backgroundImage:'url(images/foto/'+(window.innerHeight >= 992 ? "lg" : "sm" )+"/203_3.jpg)"}}></div>
													<div className="booking_overlay trans_200"></div>
													<div className="booking_item_content">
														<div className="booking_item_list">
															<ul>
																<li>15 m²</li>
																<li>Bagno privato</li>
																<li>Aria condizionata</li>
																<li>TV a schermo piatto</li>
																<li>WiFi gratis</li>
															</ul>
														</div>
													</div>
													{/*  <div className="booking_price">€50/Notte</div>  */}
													<div className="booking_link"><p>Stanza 203</p></div>
												</HashLink>
											</div>
										</ScrollAnimation>
									</div>
							

							</div>
						</div>
					</div>
				</div>
			</div>

			<div  className='mb-5' style={{borderTop: "solid 2px #eaf2f7"}}></div>
			
			{/*  Gallery  */}

			<div className="gallery py-5">
				<div className="text-center pb-4">
					<h2>Galleria</h2>
				</div>
				<div className="gallery_slider_container">
					<ScrollAnimation animateIn="fadeIn" delay={window.innerHeight >= 992 ?200:100}>
						<div className="owl-carousel owl-theme gallery_slider d-block">
							<Slider imagesDir={'url(images/foto/'+(window.innerHeight >= 992 ? "lg" : "sm" )} />
						</div>
					</ScrollAnimation>
				</div>
			</div>


			{/*  Footer  */}

			<footer className="footer">
				<div className="footer_content">
					<div className="container">
						<div className="row">
							<div className="col">
								<div className="footer_logo_container text-center">
									<div className="footer_logo">
										<a href="#"></a>
										<div className="px-1">La Casa Di Lally</div>
										<div></div>
									</div>
								</div>
							</div>
						</div>
						<div className="row footer_row justify-content-center text-center">
							
							{/*  Address  */}
							<div className="col-lg-3">
								<div className="footer_title">Indirizzo</div>
								<div className="footer_list">
									<ul>
										<li>Via aldo moro 103c</li>
										<li>Fiano romano (RM), 00065</li>
									</ul>
								</div>
							</div>

							{/*  Reservations  */}
							<div className="col-lg-3">
								<div className="footer_title">Contatti</div>
								<div className="footer_list">
									<ul>
										<li>+39 333 2488 401</li>
										<li>lacasadilally@gmail.com</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="row footer_row justify-content-center text-center">
							<p className="text-center footer_title">Seguici su:<Fab className='mx-3 bg-primary text-white' href="https://www.facebook.com/pages/category/Hotel---Lodging/La-casa-di-Lally-106434191441386/" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-f" style={{height:"14px", width:"14px"}}></i></Fab></p>
						</div>
					</div>
					<div id='map' className='map'>
						<EmbedMap width="100%" height="500px"/>
					</div>
				</div>
				<div className="copyright pb-3 pt-5">
					{/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
					Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart-o" aria-hidden="true"></i> by <a href="https://colorlib.com" rel="noopener noreferrer"  target="_blank">Colorlib</a>
					{/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
				</div>
				<div className="footer-copyright">
					<div className="container-fluid mt-2 text-center">
						<p>Website realized by <a href="https://socrateit.com/" rel="noopener noreferrer" target="_blank">SOCRATE</a></p>
					</div>
				</div>
			</footer>

		</React.Fragment>

	)
}