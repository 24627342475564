import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// import LoadingScreen from 'react-loading-screen';
import ScrollTop from './components/ScrollTop/ScrollTop';

import Home from './pages/Home';
import Contact from './pages/Contact/Contact';
import Booking from './pages/Booking/Booking';
import './App.css';

const url= '/status.json'


function App() {
  const [rootRedirect, setRootRedirect] = useState(false)
  var isLoaded = false

  async function fetchUrl() {
    const json = await fetch(url, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then((response) => response.json());
    setRootRedirect(json.rootRedirect);
  }

  useEffect(() => {
    fetchUrl();
    isLoaded = true
    }, []);

  return (
    <React.Fragment>
      {rootRedirect && (
        <Router>
          <ScrollTop/>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/booking">
                <Booking />
              </Route>
              <Redirect to={rootRedirect.url} />
            </Switch>
        </Router>
      )}
    </React.Fragment>
  );
}
export default App;