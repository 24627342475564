import React from 'react';


function EmbedMap (props) {
    return (
        <iframe
            title='Maps'
            width={props.width}
            style={{border:0, height: props.height}}
            loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA7flXYTdiM-7Ao9iCYTilqy-RzYbvnv7w&q=place_id:ChIJs_pZsjJtLxMR7HigPNHiuJg">
        </iframe>
    )
}



export default EmbedMap