import React from 'react';
import EmbedMap from 'components/EmbedMap/EmbedMap';
import {Navbar, Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import Fab from '@material-ui/core/Fab'
import styles from "./style.module.css"
import "./style.css";


export default function Booking() {

	return (
		<React.Fragment>

			{/*  Navbar  */}
			<Navbar className="header" bg="dark" variant="dark" expand="lg">
				<Navbar.Brand className="logo px-2">La Casa Di Lally</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav"  />

				<Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
					<Nav className="main_nav">
						<Link className="nav-link d-flex flex-row align-items-center justify-content-start mx-3 my-2" to="/"><span>Home</span></Link>
						<Link className="nav-link d-flex flex-row align-items-center justify-content-start active mx-3 my-2" to="/booking"><span>Stanze</span></Link>
						<Link className="nav-link d-flex flex-row align-items-center justify-content-start mx-3 my-2" to="/contact"><span>Contatti</span></Link>
						<Link className="nav-link book_button mr-auto d-flex flex-row align-items-center justify-content-center mx-3 my-2" to="/booking"><span>Prenota ora</span></Link>
						<Nav.Link className="book_button mr-auto d-flex flex-row align-items-center justify-content-center mx-3 my-2" style={{width:'170px'}} href='tel:3332488401'><i className="fas fa-phone text-white"></i><span>+39 3332488401</span></Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>


			<ScrollAnimation animateIn="fadeIn">
				<div className={styles.booking_title+" text-center mb-5 pt-5"}><h1>Le Nostre Stanze</h1></div>
			</ScrollAnimation>


			{/*  Details Right  */}c

			<div className="details">
				<ScrollAnimation animateIn="fadeIn">
					<div className="container">
						<div className="row">
							
							{/*  Details Image  */}
							<div className="col-xl-7 col-lg-6">
								<div className="details_image">
									<div className="background_image" style={{backgroundImage:"url(/images/foto/"+(window.innerHeight >= 992 ? "lg" : "sm" )+"/201_3.jpg)"}}></div>
								</div>
							</div>

							{/*  Details Content  */}
							<div id='201' className="col-xl-5 col-lg-6">
								<div className="details_content">
									<div className="details_title">Stanza 201</div>
									<div className="details_list">
										<ul>
											<li>18 m²</li>
											<li>Bagno privato</li>
											<li>Aria condizionata</li>
											<li>TV a schermo piatto</li>
											<li>WiFi gratis</li>
										</ul>
									</div>

									<div className="details_long_list">
										<ul className="d-flex flex-row align-items-start justify-content-start flex-wrap">
											<li>Balcone</li>
											<li>Terrazza</li>
											<li>Macchina del caffè</li>
											<li>Prodotti da bagno in omaggio</li>
											<li>Cucina</li>
											<li>Asciugamani</li>
											<li>Frigorifero</li>
											<li>Riscaldamento</li>
											<li>Asciugacapelli</li>
											<li>Utensili da cucina</li>
											<li>Angolo cottura</li>
											<li>Armadio o guardaroba</li>
											<li>Forno</li>
											<li>Zona pranzo</li>
										</ul>
									</div>
									<div className="book_now_button" style={{width:'170px'}} ><a href='tel:3332488401'><i className="fas fa-phone text-white p-2"></i><span>+39 3332488401</span></a></div>
									<div className="book_now_button"><a href="https://www.booking.com/hotel/it/la-casa-di-lally.it.html" rel="noopener noreferrer"  target='_blank'>Prenota</a></div>
								</div>
							</div>
						</div>
					</div>
				</ScrollAnimation>
			</div>

			{/*  Details Left  */}

			<div className="details">
				<ScrollAnimation animateIn="fadeIn">
					<div className="container">
						<div className="row">

							{/*  Details Content  */}
							<div id='202' className="col-xl-5 col-lg-6 order-lg-1 order-2">
								<div className="details_content">
									<div className="details_title">Stanza 202</div>
									<div className="details_list">
										<ul>
											<li>20 m²</li>
											<li>Bagno privato</li>
											<li>Aria condizionata</li>
											<li>TV a schermo piatto</li>
											<li>WiFi gratis</li>
										</ul>
									</div>

									<div className="details_long_list">
										<ul className="d-flex flex-row align-items-start justify-content-start flex-wrap">
											<li>Balcone</li>
											<li>Terrazza</li>
											<li>Macchina del caffè</li>
											<li>Prodotti da bagno in omaggio</li>
											<li>Cucina</li>
											<li>Asciugamani</li>
											<li>Frigorifero</li>
											<li>Riscaldamento</li>
											<li>Asciugacapelli</li>
											<li>Utensili da cucina</li>
											<li>Angolo cottura</li>
											<li>Armadio o guardaroba</li>
											<li>Forno</li>
											<li>Zona pranzo</li>
										</ul>
									</div>
									<div className="book_now_button" style={{width:'170px'}} ><a href='tel:3332488401'><i className="fas fa-phone text-white p-2"></i><span>+39 3332488401</span></a></div>
									<div className="book_now_button"><a href="https://www.booking.com/hotel/it/la-casa-di-lally.it.html" rel="noopener noreferrer"  target='_blank'>Prenota</a></div>
								</div>
							</div>

							{/*  Details Image  */}
							<div className="col-xl-7 col-lg-6 order-lg-2 order-1">
								<div className="details_image">
									<div className="background_image" style={{backgroundImage:"url(images/foto/"+(window.innerHeight >= 992 ? "lg" : "sm" )+"/202_5.jpg)"}}></div>
								</div>
							</div>
						</div>
					</div>
				</ScrollAnimation>
			</div>

			<div className="details">
				<ScrollAnimation animateIn="fadeIn">
					<div className="container">
						<div className="row">
							
							{/*  Details Image  */}
							<div className="col-xl-7 col-lg-6">
								<div className="details_image">
									<div className="background_image" style={{backgroundImage:"url(images/foto/"+(window.innerHeight >= 992 ? "lg" : "sm" )+"/203_1.jpg)"}}></div>
								</div>
							</div>

							{/*  Details Content  */}
							<div id='203' className="col-xl-5 col-lg-6">
								<div className="details_content">
									<div className="details_title">Stanza 203</div>
									<div className="details_list">
										<ul>
											<li>15 m²</li>
											<li>Bagno privato</li>
											<li>Aria condizionata</li>
											<li>TV a schermo piatto</li>
											<li>WiFi gratis</li>
										</ul>
									</div>

									<div className="details_long_list">
										<ul className="d-flex flex-row align-items-start justify-content-start flex-wrap">
											<li>Balcone</li>
											<li>Terrazza</li>
											<li>Macchina del caffè</li>
											<li>Prodotti da bagno in omaggio</li>
											<li>Cucina</li>
											<li>Asciugamani</li>
											<li>Frigorifero</li>
											<li>Riscaldamento</li>
											<li>Asciugacapelli</li>
											<li>Utensili da cucina</li>
											<li>Angolo cottura</li>
											<li>Armadio o guardaroba</li>
											<li>Forno</li>
											<li>Zona pranzo</li>
										</ul>
									</div>
									<div className="book_now_button" style={{width:'170px'}} ><a href='tel:3332488401'><i className="fas fa-phone text-white p-2"></i><span>+39 3332488401</span></a></div>
									<div className="book_now_button"><a href="https://www.booking.com/hotel/it/la-casa-di-lally.it.html" rel="noopener noreferrer" target='_blank'>Prenota</a></div>
								</div>
							</div>
						</div>
					</div>
				</ScrollAnimation>
			</div>

			{/*  Footer  */}

			<footer className="footer">
				<div className="footer_content">
					<div className="container">
						<div className="row">
							<div className="col">
								<div className="footer_logo_container text-center">
									<div className="footer_logo">
										<div className="px-1">La Casa Di Lally</div>
										<div></div>
									</div>
								</div>
							</div>
						</div>
						<div className="row footer_row justify-content-center text-center">
							
							{/*  Address  */}
							<div className="col-lg-3">
								<div className="footer_title">Indirizzo</div>
								<div className="footer_list">
									<ul>
										<li>Via aldo moro 103c</li>
										<li>Fiano romano (RM), 00065</li>
									</ul>
								</div>
							</div>

							{/*  Reservations  */}
							<div className="col-lg-3">
								<div className="footer_title">Contatti</div>
								<div className="footer_list">
									<ul>
										<li>+39 333 2488 401</li>
										<li>lacasadilally@gmail.com</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="row footer_row justify-content-center text-center">
							<p className="text-center footer_title">Seguici su:<Fab className='mx-3 bg-primary text-white' href="https://www.facebook.com/pages/category/Hotel---Lodging/La-casa-di-Lally-106434191441386/" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-f" style={{height:"14px", width:"14px"}}></i></Fab></p>
						</div>
					</div>
					<div id='map' className="map"> 						
					<EmbedMap width="100%" height="500px"/>
					</div>
				</div>
				<div className="copyright pb-3 pt-5">
					{/*  Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0.  */}
					Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart-o" aria-hidden="true"></i> by <a href="https://colorlib.com" rel="noopener noreferrer" target="_blank">Colorlib</a>
					{/*  Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0.  */}
				</div>
				<div className="footer-copyright">
					<div className="container-fluid mt-2 text-center">
						<p>Website realized by <a href="https://socrateit.com/" rel="noopener noreferrer" target="_blank">SOCRATE</a></p>
					</div>
				</div>
			</footer>

		</React.Fragment>
	)
}